import { gql } from "@apollo/client";

export const VARIABLE_TIME_RANGE_DISCOUNT_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		discount
	}
`;

export const VARIABLE_TIME_RANGE_DIFF_PREVIOUS_LINE_FRAGMENT = gql`
	fragment VariableTimeRangeDiffPreviousLineFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		diffPreviousRange
	}
`;

export const VARIABLE_TIME_RANGE_DISCOUNT_FULL_FRAGMENT = gql`
	fragment VariableTimeRangeDiscountFullFragment on VariableTimeRangeDiscount {
		id
		packId
		variableTimeRange {
			id
		}
		discount
		price
		totalPrice
		diffPreviousRange
	}
`;
