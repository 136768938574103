import { gql } from "@apollo/client";

export const PRICE_MODEL_METRIC_IS_DEFAULT_FRAGMENT = gql`
	fragment PriceModelMetricIsDefault on PriceModelMetric {
		id
		isDefault
	}
`;

export const PRICE_MODEL_METRIC_IS_TOTAL_FRAGMENT = gql`
	fragment PriceModelMetricIsTotal on PriceModelMetric {
		id
		isTotal
	}
`;

export const PRICE_MODEL_METRIC_USED_AS_PRIMARY_FRAGMENT = gql`
	fragment PriceModelMetricUsedAsPrimary on PriceModelMetric {
		id
		usedAsPrimary
	}
`;

export const PRICE_MODEL_METRIC_USED_AS_SECONDARY_FRAGMENT = gql`
	fragment PriceModelMetricUsedAsSecondary on PriceModelMetric {
		id
		usedAsSecondary
	}
`;

export const PRICE_MODEL_METRIC_PRICE_MODEL_FEATURE_ID_FRAGMENT = gql`
	fragment PriceModelMetricPriceModelFeatureId on PriceModelMetric {
		id
		priceModelFeatureId
	}
`;

export const PRICE_MODEL_METRIC_VOLUME_DISCOUNT_ID_FRAGMENT = gql`
	fragment PriceModelMetricVolumeDiscountId on PriceModelMetric {
		id
		volumeDiscountId
	}
`;

export const PRICE_MODEL_METRIC_VOLUME_DISCOUNT_LINK_ID_FRAGMENT = gql`
	fragment PriceModelMetricVolumeDiscountLinkId on PriceModelMetric {
		id
		volumeDiscountLinkId
		volumeDiscountLink {
			id
			volumeDiscounts {
				id
			}
		}
	}
`;

export const PRICE_MODEL_METRIC_FRAGMENT = gql`
	${PRICE_MODEL_METRIC_IS_DEFAULT_FRAGMENT}
	${PRICE_MODEL_METRIC_IS_TOTAL_FRAGMENT}
	${PRICE_MODEL_METRIC_USED_AS_PRIMARY_FRAGMENT}
	${PRICE_MODEL_METRIC_USED_AS_SECONDARY_FRAGMENT}
	${PRICE_MODEL_METRIC_PRICE_MODEL_FEATURE_ID_FRAGMENT}
	${PRICE_MODEL_METRIC_VOLUME_DISCOUNT_ID_FRAGMENT}
	${PRICE_MODEL_METRIC_VOLUME_DISCOUNT_LINK_ID_FRAGMENT}
	fragment PriceModelMetric on PriceModelMetric {
		id
		priceModelId
		uuid
		...PriceModelMetricIsDefault
		...PriceModelMetricIsTotal
		...PriceModelMetricUsedAsPrimary
		...PriceModelMetricUsedAsSecondary
		...PriceModelMetricPriceModelFeatureId
		...PriceModelMetricVolumeDiscountId
		...PriceModelMetricVolumeDiscountLinkId
		priceModelFeature {
			feature {
				id
				name
				numerical {
					id
					unitMetric
					timeMetric
					isRange
					displayedAs
				}
			}
		}
		volumeDiscount {
			id
			unitMetric
			volumeDiscountLinkId
			discountRule {
				id
				name
			}
		}
		volumeDiscountLink {
			id
			unitMetric
		}
	}
`;
