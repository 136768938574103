import { gql } from "@apollo/client";
import {
	INDEX_SPECIAL_RANGE,
	IS_DEFAULT_SELECTED_SPECIAL_RANGE_FRAGMENT,
	NAME_SPECIAL_RANGE,
	SPECIAL_RANGE_FRAGMENT,
} from "@graphQl/fragments/specialRange";

export const IS_DISCOUNT_ADDON_OR_EXTRA_SPECIAL_DISCOUNT_FRAGMENT = gql`
	fragment IsAddonOrExtraSpecialDiscountFragment on SpecialDiscount {
		id
		isAddonOrExtra
	}
`;

export const TYPE_AND_UNIT_METRIC_SPECIAL_DISCOUNT_FRAGMENT = gql`
	fragment TypeAndUnitMetricSpecialDiscountFragment on SpecialDiscount {
		id
		type
		unitMetric
	}
`;

export const SPECIAL_DISCOUNT_SPECIAL_RANGE_INDEX_FRAGMENT = gql`
	${INDEX_SPECIAL_RANGE}
	fragment SpecialDiscountSpecialRangeIndexFragment on SpecialDiscount {
		id
		specialRange {
			...IndexSpecialRangeFragment
		}
	}
`;

export const SPECIAL_DISCOUNT_SPECIAL_RANGE_NAME_FRAGMENT = gql`
	${NAME_SPECIAL_RANGE}
	fragment SpecialDiscountSpecialRangeNameFragment on SpecialDiscount {
		id
		type
		specialRange {
			...NameSpecialRangeFragment
		}
	}
`;

export const SPECIAL_DISCOUNT_IS_LOCKED_FRAGMENT = gql`
	fragment SpecialDiscountIsLockedFragment on SpecialDiscount {
		id
		isLocked
	}
`;

export const SPECIAL_DISCOUNT_POSITION_FRAGMENT = gql`
	fragment SpecialDiscountPositionFragment on SpecialDiscount {
		id
		position
	}
`;

export const SPECIAL_DISCOUNT_FRAGMENT = gql`
	${SPECIAL_RANGE_FRAGMENT}
	${IS_DISCOUNT_ADDON_OR_EXTRA_SPECIAL_DISCOUNT_FRAGMENT}
	${SPECIAL_DISCOUNT_IS_LOCKED_FRAGMENT}
	${SPECIAL_DISCOUNT_POSITION_FRAGMENT}
	${IS_DEFAULT_SELECTED_SPECIAL_RANGE_FRAGMENT}
	fragment SpecialDiscountFragment on SpecialDiscount {
		...IsAddonOrExtraSpecialDiscountFragment
		...SpecialDiscountIsLockedFragment
		...SpecialDiscountPositionFragment
		id
		uuid
		type
		unitMetric
		timeMetric
		specialRange {
			...SpecialRangeFragment
			...IsDefaultSelectedSpecialRangeFragment
		}
		defaultPositions {
			packId
			specialDiscountId
			specialRangeId
			isAddonExtraLocked
		}
	}
`;
