import { gql } from "@apollo/client";
import {
	NUMERICAL_RANGE_FRAGMENT,
	PUBLIC_NUMERICAL_RANGE_FRAGMENT,
} from "@graphQl/fragments/numericalRange";

export const NUMERICAL_IS_RANGE_FRAGMENT = gql`
	fragment NumericalIsRangeFragment on Numerical {
		id
		isRange
	}
`;

export const NUMERICAL_DISPLAYED_AS_FRAGMENT = gql`
	fragment NumericalDisplayedAsFragment on Numerical {
		id
		displayedAs
	}
`;

export const NUMERICAL_DECIMALS_NUMBER_FRAGMENT = gql`
	fragment NumericalDecimalsNumberFragment on Numerical {
		id
		decimalsNumber
	}
`;

export const NUMERICAL_UNIT_METRIC_FRAGMENT = gql`
	${NUMERICAL_IS_RANGE_FRAGMENT}
	fragment NumericalUnitMetricFragment on Numerical {
		id
		unitMetric
		...NumericalIsRangeFragment
	}
`;

export const NUMERICAL_TIME_METRIC_FRAGMENT = gql`
	${NUMERICAL_IS_RANGE_FRAGMENT}
	fragment NumericalTimeMetricFragment on Numerical {
		id
		timeMetric
		...NumericalIsRangeFragment
	}
`;

export const NUMERICAL_SORT_RANGES_FRAGMENT = gql`
	${NUMERICAL_IS_RANGE_FRAGMENT}
	fragment NumericalSortRangesFragment on Numerical {
		id
		sortRanges
		...NumericalIsRangeFragment
	}
`;

export const NUMERICAL_PLURAL_METRIC_FRAGMENT = gql`
	${NUMERICAL_IS_RANGE_FRAGMENT}
	fragment NumericalPluralMetricFragment on Numerical {
		id
		pluralMetric
		...NumericalIsRangeFragment
	}
`;

export const NUMERICAL_FRAGMENT = gql`
	${NUMERICAL_UNIT_METRIC_FRAGMENT}
	${NUMERICAL_TIME_METRIC_FRAGMENT}
	${NUMERICAL_SORT_RANGES_FRAGMENT}
	${NUMERICAL_IS_RANGE_FRAGMENT}
	${NUMERICAL_PLURAL_METRIC_FRAGMENT}
	${NUMERICAL_RANGE_FRAGMENT}
	${NUMERICAL_DISPLAYED_AS_FRAGMENT}
	${NUMERICAL_DECIMALS_NUMBER_FRAGMENT}
	fragment NumericalFragment on Numerical {
		id
		...NumericalDisplayedAsFragment
		...NumericalUnitMetricFragment
		...NumericalTimeMetricFragment
		...NumericalSortRangesFragment
		...NumericalIsRangeFragment
		...NumericalPluralMetricFragment
		...NumericalDecimalsNumberFragment
		ranges {
			...NumericalRangeFragment
		}
	}
`;

export const PUBLIC_NUMERICAL_FRAGMENT = gql`
	${NUMERICAL_UNIT_METRIC_FRAGMENT}
	${NUMERICAL_TIME_METRIC_FRAGMENT}
	${NUMERICAL_SORT_RANGES_FRAGMENT}
	${NUMERICAL_IS_RANGE_FRAGMENT}
	${NUMERICAL_PLURAL_METRIC_FRAGMENT}
	${PUBLIC_NUMERICAL_RANGE_FRAGMENT}
	${NUMERICAL_DISPLAYED_AS_FRAGMENT}
	${NUMERICAL_DECIMALS_NUMBER_FRAGMENT}
	fragment PublicNumericalFragment on Numerical {
		id
		...NumericalUnitMetricFragment
		...NumericalTimeMetricFragment
		...NumericalSortRangesFragment
		...NumericalIsRangeFragment
		...NumericalPluralMetricFragment
		...NumericalDisplayedAsFragment
		...NumericalDecimalsNumberFragment
		ranges {
			...PublicNumericalRangeFragment
		}
	}
`;
