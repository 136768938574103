import { gql } from "@apollo/client";
import { ERROR_FRAGMENT } from "@graphQl/fragments/error";

export const PACK_ID_FRAGMENT = gql`
	fragment PackIdFragment on Pack {
		id
	}
`;

export const PACK_IS_REFERENCE_FRAGMENT = gql`
	fragment PackIsReferenceFragment on Pack {
		id
		isReference
	}
`;

export const PACK_IS_HIGHLIGHTED_FRAGMENT = gql`
	fragment PackIsHighlightedFragment on Pack {
		id
		isHighlighted
	}
`;

export const PACK_IS_DEFAULT_SELECTED_FRAGMENT = gql`
	fragment PackIsDefaultSelectedFragment on Pack {
		id
		isDefaultSelected
	}
`;

export const PACK_IS_DISPLAYED_FRAGMENT = gql`
	fragment PackIsDisplayedFragment on Pack {
		id
		isDisplayed
	}
`;

export const PACK_INDEX_FRAGMENT = gql`
	fragment PackIndexFragment on Pack {
		id
		index
	}
`;

export const PACK_PRICE_FRAGMENT = gql`
	fragment PackPriceFragment on Pack {
		id
		price
	}
`;

export const PACK_PRICE_MODEL_ID_FRAGMENT = gql`
	fragment PackPriceModelIdFragment on Pack {
		id
		priceModelId
	}
`;

export const PACK_IMAGE_FRAGMENT = gql`
	fragment PackImageFragment on Pack {
		id
		packImage
		imageFormat
	}
`;

export const PACK_COLOR_FRAGMENT = gql`
	fragment PackColorFragment on Pack {
		id
		color
	}
`;

export const PACK_IS_CONTACT_LINK_ACTIVE_FRAGMENT = gql`
	fragment PackIsContactLinkActiveFragment on Pack {
		id
		isContactLinkActive
	}
`;

export const PACK_PRICE_NAME_FRAGMENT = gql`
	fragment PackPriceNameFragment on Pack {
		id
		priceName
	}
`;

export const PACK_ERRORS_FRAGMENT = gql`
	${ERROR_FRAGMENT}
	fragment PackErrorsFragment on Pack {
		id
		errors {
			...ErrorFragment
		}
	}
`;

export const PACK_CONTACT_LINK_FRAGMENT = gql`
	fragment PackContactLinkFragment on Pack {
		id
		contactLink
	}
`;

export const PACK_TYPE_FRAGMENT = gql`
	fragment PackTypeFragment on Pack {
		id
		type
		uuid
	}
`;

export const PACK_MINIMUM_PRICE_FRAGMENT = gql`
	fragment PackMinimumPriceFragment on Pack {
		id
		minimumPrice
	}
`;

export const PACK_TAG_LINE_FRAGMENT = gql`
	fragment PackTagLineFragment on Pack {
		id
		tagLine
	}
`;

export const PACK_BUTTON_NAME_FRAGMENT = gql`
	fragment PackButtonNameFragment on Pack {
		id
		buttonName
	}
`;

export const PACK_VOLUME_DISCOUNT_RANGE_FRAGMENT = gql`
	fragment PackVolumeDiscountRangeFragment on Pack {
		volumeDiscountRange {
			id
			volumeRangeId
			price
			packId
		}
	}
`;

export const PACK_TYPE_PRICE_AND_PRICE_NAME_FRAGMENT = gql`
	${PACK_TYPE_FRAGMENT}
	${PACK_PRICE_FRAGMENT}
	${PACK_PRICE_NAME_FRAGMENT}
	fragment PackTypePriceAndPriceNameFragment on Pack {
		id
		...PackTypeFragment
		...PackPriceFragment
		...PackPriceNameFragment
	}
`;

export const PACK_FOR_SELECTED_REFERENCE_FRAGMENT = gql`
	${PACK_IS_REFERENCE_FRAGMENT}
	fragment PackForSelectedReferenceFragment on Pack {
		id
		packProperties {
			id
			name
		}
		...PackIsReferenceFragment
	}
`;

export const PACK_NAME_FRAGMENT = gql`
	fragment PackNameFragment on Pack {
		id
		packProperties {
			id
			name
		}
	}
`;

export const PACK_MINIMUM_PRICE_DATA_FRAGMENT = gql`
	${PACK_MINIMUM_PRICE_FRAGMENT}
	${PACK_INDEX_FRAGMENT}
	${PACK_TYPE_FRAGMENT}
	${PACK_PRICE_FRAGMENT}

	fragment PackMinimumPriceDataFragment on Pack {
		id
		...PackMinimumPriceFragment
		...PackIndexFragment
		...PackTypeFragment
		...PackPriceFragment
	}
`;

export const PACK_PROFITABILITY_FRAGMENT = gql`
	fragment PackProfitabilityFragment on Pack {
		id
		profitabilityPack {
			id
			packProfitability
		}
	}
`;

export const PROFITABILITY_PACK_VARIABLE_COST_AND_PACK_PRICE_FRAGMENT = gql`
	fragment ProfitabilityPackVariableCostAndPackPriceFragment on Pack {
		id
		profitabilityPack {
			id
			packPrice
			variableCost
		}
	}
`;

export const PROFITABILITY_PACK_DATA_FRAGMENT = gql`
	fragment ProfitabilityPackDataPriceModelFragment on Pack {
		id
		profitabilityPack {
			id
			packPrice
			boosterPrice
			variableCost
			boosterProfitability
			packProfitability
			missingPrice {
				id
				name
			}
			missingVariableCost {
				id
				name
			}
		}
	}
`;

export const SUGGESTED_PACK_FRAGMENT = gql`
	fragment SuggestedPackPriceModelFragment on Pack {
		id
		packProperties {
			id
			name
		}
		price
		type
		suggestedPack {
			suggestedPrice
			score
		}
	}
`;

export const PACK_FRAGMENT = gql`
	${PACK_PRICE_FRAGMENT}
	${PACK_IMAGE_FRAGMENT}
	${PACK_COLOR_FRAGMENT}
	${PACK_INDEX_FRAGMENT}
	${PACK_IS_CONTACT_LINK_ACTIVE_FRAGMENT}
	${PACK_PRICE_NAME_FRAGMENT}
	${PACK_CONTACT_LINK_FRAGMENT}
	${PACK_TYPE_FRAGMENT}
	${PACK_PROFITABILITY_FRAGMENT}
	${PACK_BUTTON_NAME_FRAGMENT}
	${PACK_IS_REFERENCE_FRAGMENT}
	${PACK_IS_HIGHLIGHTED_FRAGMENT}
	${PACK_IS_DEFAULT_SELECTED_FRAGMENT}
	fragment PackPriceModelFragment on Pack {
		id
		uuid
		priceModelId
		createdBy
		updatedBy
		createdAt
		updatedAt
		tagLine
		packProperties {
			id
			name
			description
		}
		...PackIsHighlightedFragment
		...PackIsReferenceFragment
		...PackTypeFragment
		...PackPriceNameFragment
		...PackContactLinkFragment
		...PackIsContactLinkActiveFragment
		...PackIndexFragment
		...PackPriceFragment
		...PackImageFragment
		...PackColorFragment
		...PackProfitabilityFragment
		...PackButtonNameFragment
		...PackIsDefaultSelectedFragment
	}
`;

export const PACK_COLORLESS_FRAGMENT = gql`
	${PACK_PRICE_FRAGMENT}
	${PACK_IMAGE_FRAGMENT}
	${PACK_INDEX_FRAGMENT}
	${PACK_IS_CONTACT_LINK_ACTIVE_FRAGMENT}
	${PACK_PRICE_NAME_FRAGMENT}
	${PACK_CONTACT_LINK_FRAGMENT}
	${PACK_TYPE_FRAGMENT}
	${PACK_PROFITABILITY_FRAGMENT}
	${PACK_BUTTON_NAME_FRAGMENT}
	${PACK_IS_REFERENCE_FRAGMENT}
	${PACK_IS_HIGHLIGHTED_FRAGMENT}
	${PACK_IS_DEFAULT_SELECTED_FRAGMENT}
	fragment PackColorlessPriceModelFragment on Pack {
		id
		priceModelId
		createdBy
		updatedBy
		createdAt
		updatedAt
		tagLine
		packProperties {
			id
			name
			description
		}
		...PackIsHighlightedFragment
		...PackIsReferenceFragment
		...PackTypeFragment
		...PackPriceNameFragment
		...PackContactLinkFragment
		...PackIsContactLinkActiveFragment
		...PackIndexFragment
		...PackPriceFragment
		...PackImageFragment
		...PackProfitabilityFragment
		...PackButtonNameFragment
		...PackIsDefaultSelectedFragment
	}
`;

export const PACK_PRICE_NAME_AND_TYPE_FRAGMENT = gql`
	${PACK_PRICE_NAME_FRAGMENT}
	${PACK_TYPE_FRAGMENT}
	fragment PackPriceNameAndTypeFragment on Pack {
		...PackPriceNameFragment
		...PackTypeFragment
	}
`;

export const PUBLIC_PACK_FRAGMENT = gql`
	${PACK_TAG_LINE_FRAGMENT}
	${PACK_PRICE_FRAGMENT}
	${PACK_IMAGE_FRAGMENT}
	${PACK_COLOR_FRAGMENT}
	${PACK_INDEX_FRAGMENT}
	${PACK_IS_CONTACT_LINK_ACTIVE_FRAGMENT}
	${PACK_PRICE_NAME_FRAGMENT}
	${PACK_CONTACT_LINK_FRAGMENT}
	${PACK_TYPE_FRAGMENT}
	${PACK_PRICE_MODEL_ID_FRAGMENT}
	${PACK_BUTTON_NAME_FRAGMENT}
	${PACK_IS_DISPLAYED_FRAGMENT}
	fragment PublicPackPriceModelFragment on Pack {
		id
		...PackIsDisplayedFragment
		...PackTagLineFragment
		...PackTypeFragment
		...PackPriceNameFragment
		...PackContactLinkFragment
		...PackIsContactLinkActiveFragment
		...PackIndexFragment
		...PackPriceFragment
		...PackImageFragment
		...PackColorFragment
		...PackPriceModelIdFragment
		...PackButtonNameFragment
		packProperties {
			id
			name
			description
		}
	}
`;
