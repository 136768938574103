import { gql } from "@apollo/client";
import { ENGAGEMENT_RANGE_FULL_FRAGMENT } from "@graphQl/fragments/engagementRangeFragment";

export const IS_LOCKED_ENGAGEMENT_DISCOUNT_FRAGMENT = gql`
	fragment IsLockedEngagementDiscountFragment on EngagementDiscount {
		id
		isLocked
	}
`;

export const SORT_ORDER_ENGAGEMENT_DISCOUNT_FRAGMENT = gql`
	fragment SortOrderEngagementDiscountFragment on EngagementDiscount {
		id
		sortOrder
	}
`;

export const ENGAGEMENT_DISCOUNT_ENGAGEMENT_RANGE_FRAGMENT = gql`
	${ENGAGEMENT_RANGE_FULL_FRAGMENT}
	${IS_LOCKED_ENGAGEMENT_DISCOUNT_FRAGMENT}
	${SORT_ORDER_ENGAGEMENT_DISCOUNT_FRAGMENT}
	fragment EngagementDiscountEngagementRangeFragment on EngagementDiscount {
		id
		uuid
		sortOrder
		...IsLockedEngagementDiscountFragment
		...SortOrderEngagementDiscountFragment
		engagementRange {
			...EngagementRangeFullFragment
		}
	}
`;
