import { gql } from "@apollo/client";

export const NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT = gql`
	fragment NumericalRangeFromNameUnlimitedFragment on NumericalRange {
		id
		name
		from
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_FROM_FRAGMENT = gql`
	fragment NumericalRangeFromFragment on NumericalRange {
		id
		from
	}
`;

export const NUMERICAL_RANGE_NAME_FRAGMENT = gql`
	fragment NumericalRangeNameFragment on NumericalRange {
		id
		name
	}
`;

export const NUMERICAL_RANGE_TO_FRAGMENT = gql`
	fragment NumericalRangeToFragment on NumericalRange {
		id
		to
	}
`;

export const NUMERICAL_RANGE_INDEX_FRAGMENT = gql`
	fragment NumericalRangeIndexFragment on NumericalRange {
		id
		index
	}
`;

export const NUMERICAL_RANGE_DESCRIPTION_FRAGMENT = gql`
	fragment NumericalRangeDescriptionFragment on NumericalRange {
		id
		description
	}
`;

export const NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT = gql`
	fragment NumericalRangeIsUnlimitedFragment on NumericalRange {
		id
		isUnlimited
	}
`;

export const NUMERICAL_RANGE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_NAME_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_TO_FRAGMENT}
	fragment NumericalRangeFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromNameUnlimitedFragment
		...NumericalRangeToFragment
		...NumericalRangeIndexFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		errors {
			id
			from
		}
	}
`;

export const PUBLIC_NUMERICAL_RANGE_FRAGMENT = gql`
	${NUMERICAL_RANGE_FROM_FRAGMENT}
	${NUMERICAL_RANGE_INDEX_FRAGMENT}
	${NUMERICAL_RANGE_DESCRIPTION_FRAGMENT}
	${NUMERICAL_RANGE_IS_UNLIMITED_FRAGMENT}
	${NUMERICAL_RANGE_TO_FRAGMENT}
	${NUMERICAL_RANGE_NAME_FRAGMENT}
	fragment PublicNumericalRangeFragment on NumericalRange {
		id
		numericalId
		...NumericalRangeFromFragment
		...NumericalRangeToFragment
		...NumericalRangeIndexFragment
		...NumericalRangeDescriptionFragment
		...NumericalRangeIsUnlimitedFragment
		...NumericalRangeNameFragment
	}
`;
