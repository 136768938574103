import { useMemo } from "react";
import { useQuery, useSuspenseQuery } from "@apollo/client";
import { GET_CURRENT_WORKSPACE } from "@hooks/useWorkspace/graphQl/query";
import { useAuth } from "@hooks/useAuth/UseAuth";
export const useWorkspace = () => {
	const {
		loading: loadingWorkspace,
		error: errorWorkspace,
		data: dataWorkspace,
	} = useQuery(GET_CURRENT_WORKSPACE, {});

	const moduleWorkspacePermissions = useMemo(() => {
		return dataWorkspace?.getCurrentWorkspace?.moduleWorkspacePermissions || [];
	}, [dataWorkspace]);

	const transformedModuleWorkspacePermissions = useMemo(() => {
		const transformedModuleWorkspacePermissions = {};
		moduleWorkspacePermissions.forEach(moduleWorkspacePermission => {
			const { moduleName } = moduleWorkspacePermission;
			transformedModuleWorkspacePermissions[moduleName] = moduleWorkspacePermission;
		});
		return transformedModuleWorkspacePermissions;
	}, [moduleWorkspacePermissions]);

	const workspace = useMemo(() => {
		const workspaceQuery = { ...dataWorkspace?.getCurrentWorkspace };
		if (workspaceQuery) {
			delete workspaceQuery.moduleWorkspacePermissions;
		}
		return workspaceQuery || {};
	}, [dataWorkspace]);

	return {
		moduleWorkspacePermissions,
		transformedModuleWorkspacePermissions,
		workspace,
		loadingWorkspace,
		errorWorkspace,
	};
};

export const useSuspenseWorkspace = () => {
	const {
		loading: loadingWorkspace,
		error: errorWorkspace,
		data: dataWorkspace,
	} = useSuspenseQuery(GET_CURRENT_WORKSPACE, {});

	const moduleWorkspacePermissions = useMemo(
		() => dataWorkspace?.getCurrentWorkspace?.moduleWorkspacePermissions || [],
		[dataWorkspace]
	);

	const transformedModuleWorkspacePermissions = useMemo(() => {
		const obj = {};
		moduleWorkspacePermissions.forEach(moduleWorkspacePermission => {
			const { moduleName } = moduleWorkspacePermission;
			obj[moduleName] = moduleWorkspacePermission;
		});
		return obj;
	}, [moduleWorkspacePermissions]);

	const workspace = useMemo(() => {
		const workspaceQuery = { ...dataWorkspace?.getCurrentWorkspace };
		if (workspaceQuery) {
			delete workspaceQuery.moduleWorkspacePermissions;
		}
		return workspaceQuery || {};
	}, [dataWorkspace]);

	return {
		moduleWorkspacePermissions,
		transformedModuleWorkspacePermissions,
		workspace,
		loadingWorkspace,
		errorWorkspace,
	};
};

export const useSimpleWorkspace = () => {
	const { auth } = useAuth();

	const {
		loading: loadingWorkspace,
		error: errorWorkspace,
		data: dataWorkspace,
	} = useQuery(GET_CURRENT_WORKSPACE, {
		skip: !auth?.id,
	});

	const workspace = useMemo(() => {
		const workspaceQuery = { ...dataWorkspace?.getCurrentWorkspace };
		if (workspaceQuery) {
			delete workspaceQuery.moduleWorkspacePermissions;
		}
		return workspaceQuery || {};
	}, [dataWorkspace]);

	return {
		workspace,
		loadingWorkspace,
		errorWorkspace,
	};
};
