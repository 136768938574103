import { gql } from "@apollo/client";

export const SELECT_OPTION_VALUE_FRAGMENT = gql`
	fragment SelectOptionValueFragment on SelectOption {
		id
		value
	}
`;

export const SELECT_OPTION_INDEX_FRAGMENT = gql`
	fragment SelectOptionIndexFragment on SelectOption {
		id
		index
	}
`;

export const SELECT_OPTION_DESCRIPTION_FRAGMENT = gql`
	fragment SelectOptionDescriptionFragment on SelectOption {
		id
		description
	}
`;

export const SELECT_OPTION_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment SelectOptionFragment on SelectOption {
		id
		selectId
		...SelectOptionValueFragment
		...SelectOptionIndexFragment
		...SelectOptionDescriptionFragment
	}
`;

export const PUBLIC_SELECT_OPTION_FRAGMENT = gql`
	${SELECT_OPTION_VALUE_FRAGMENT}
	${SELECT_OPTION_INDEX_FRAGMENT}
	${SELECT_OPTION_DESCRIPTION_FRAGMENT}
	fragment PublicSelectOptionFragment on SelectOption {
		id
		selectId
		...SelectOptionValueFragment
		...SelectOptionIndexFragment
		...SelectOptionDescriptionFragment
	}
`;
