import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { client } from "../../../../config/apollo";
import { ApolloProvider, useApolloClient, useQuery } from "@apollo/client";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Navigate } from "react-router";
import { VERIFY_AUTH } from "@graphQl/queries/verifyAuth";
import WrongWorkspace from "@pages/workspaces/wrongWorkspace/WrongWorkspace";
import { useGlobalContext } from "@globalContext";
import { GET_AUTH_FOR_LOGIN } from "@graphQl/queries/user";

import { Loader } from "priceit-ui";
import analytics from "../../../../config/analytics/analytics";
import openReplayTracker from "../../../../config/openReplay";
import { updateUserDefaultWorkspaceMutation } from "@graphQl/mutations/user";
import { currentWorkspaceIdVar } from "@src/cache/cache";
import { logoutMutation } from "@graphQl/mutations/logout";
import { validateEmail } from "@services/validateEmail/validateEmail";
import { useSimpleWorkspace } from "@hooks/useWorkspace/UseWorkspace";

export const AuthenticatedRoute = ({ children }) => {
	const { i18n } = useTranslation();
	const { workspaceId } = useParams();
	const location = useLocation();

	const navigate = useNavigate();
	const logoutFromMutation = logoutMutation();
	const apolloClient = useApolloClient();

	const analyticsEnrichRef = useRef();
	const { workspace } = useSimpleWorkspace();

	const {
		data: dataAuth,
		loading: loadingAuth,
		refetch,
	} = useQuery(GET_AUTH_FOR_LOGIN, {
		variables: {
			workspaceId,
		},
	});
	const auth = dataAuth?.getAuth;
	const [updateUserDefaultWorkspace] = updateUserDefaultWorkspaceMutation();

	const { light } = useGlobalContext();
	const { loading: loadingVerifyAuth, data: dataVerifyAuth } = useQuery(VERIFY_AUTH);

	useEffect(() => {
		if (auth?.language) {
			i18n.changeLanguage(auth.language);
		}
		if (auth && window.$crisp) {
			if (auth?.email && validateEmail(auth?.email)) {
				window.$crisp.push(["set", "user:email", [auth?.email]]);
			}
			if (auth?.username) {
				window.$crisp.push(["set", "user:nickname", [auth?.username]]);
			}
		}
	}, [auth]);

	useEffect(() => {
		if (location.state?.refreshRoles) {
			refetch();
		}
	}, [location.state?.refreshRoles]);

	const logout = async () => {
		await logoutFromMutation();
		localStorage.removeItem("jwtToken");
		apolloClient?.clearStore();
		navigate(`/login`);
	};
	useEffect(() => {
		if (
			!loadingVerifyAuth &&
			!loadingAuth &&
			!auth?.isRoot &&
			auth?.roles?.every(role => !role?.workspace?.active)
		) {
			logout();
		}
	}, [loadingVerifyAuth, loadingAuth]);

	useEffect(() => {
		if (analyticsEnrichRef.current) {
			analyticsEnrichRef.current();
		}

		analyticsEnrichRef.current = analytics.on("trackStart", ({ payload }) => {
			if (payload.properties) {
				payload.properties.workspaceId = workspaceId;
				payload.properties.workspaceName = workspace?.name;
			} else {
				payload.properties = {
					workspaceId,
					workspaceName: workspace?.name,
				};
			}
		});
	}, [workspace]);

	if (!loadingVerifyAuth && !loadingAuth) {
		if (!dataVerifyAuth?.verifyAuth) {
			return (
				<Navigate
					to="/login"
					replace
					state={{ redirectionPath: location.pathname, search: location.search }}
				/>
			);
		}
		if (analytics.user("userId") !== auth?.uuid) {
			analytics.identify(auth?.uuid, auth);
		}
		openReplayTracker.setUserID(auth?.email);

		const hasAccessToCurrentWorkspace = auth.roles?.some(
			role =>
				role?.workspace.id &&
				parseInt(role.workspace.id) === parseInt(workspaceId) &&
				(role.workspace.active || auth.isRoot)
		);

		if (hasAccessToCurrentWorkspace || location.state?.refreshRoles) {
			currentWorkspaceIdVar(workspaceId);
			const newClient = client(null, workspaceId);
			if (auth.defaultWorkspace !== parseInt(workspaceId)) {
				updateUserDefaultWorkspace({
					variables: {
						updateUserInput: {
							defaultWorkspace: parseInt(workspaceId),
						},
					},
				});
			}

			return <ApolloProvider client={newClient}>{children}</ApolloProvider>;
		}
		if (light) {
			return <WrongWorkspace />;
		}
		const hasAccessToDefaultWorkspace = auth.roles?.some(
			role =>
				role?.workspace.id &&
				parseInt(role.workspace.id) === parseInt(auth.defaultWorkspace) &&
				(role.workspace.active || auth.isRoot)
		);
		if (hasAccessToDefaultWorkspace) {
			return <Navigate to={`/workspaces/${auth.defaultWorkspace}`} />;
		}
		const hasAccessToOneWorkspace = auth.roles?.find(
			role => role?.workspace.id && role.workspace.active
		);
		return <Navigate to={`/workspaces/${hasAccessToOneWorkspace.workspace.id}`} />;
	}

	return <Loader />;
};
