import { gql } from "@apollo/client";
import { PUBLIC_SELECT_FRAGMENT, SELECT_FRAGMENT } from "@graphQl/fragments/select";
import {
	NUMERICAL_DISPLAYED_AS_FRAGMENT,
	NUMERICAL_FRAGMENT,
	NUMERICAL_TIME_METRIC_FRAGMENT,
	NUMERICAL_UNIT_METRIC_FRAGMENT,
	PUBLIC_NUMERICAL_FRAGMENT,
} from "@graphQl/fragments/numerical";
import { PUBLIC_SWITCH_FRAGMENT, SWITCH_FRAGMENT } from "@graphQl/fragments/switch";

export const FEATURE_KEY_FIELD_FRAGMENT = gql`
	fragment FeatureKeyFieldFragment on Feature {
		id
		uuid
	}
`;

export const FEATURE_DESCRIPTION_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureDescriptionFragment on Feature {
		...FeatureKeyFieldFragment
		description
	}
`;

export const FEATURE_CREATED_AT_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureCreatedAtFragment on Feature {
		...FeatureKeyFieldFragment
		createdAt
	}
`;

export const FEATURE_LINK_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureLinkFragment on Feature {
		...FeatureKeyFieldFragment
		link
	}
`;

export const FEATURE_SECTIONS_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureSectionsFragment on Feature {
		...FeatureKeyFieldFragment
		sections
	}
`;

export const FEATURE_NAME_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureNameFragment on Feature {
		...FeatureKeyFieldFragment
		name
	}
`;

export const FEATURE_MEDIA_URL_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureMediaURLFragment on Feature {
		...FeatureKeyFieldFragment
		mediaURL
	}
`;

export const FEATURE_SHOW_URL_PREVIEW = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureShowURLPreviewFragment on Feature {
		...FeatureKeyFieldFragment
		showURLPreview
	}
`;

export const FEATURE_INPUT_TYPE_ID_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureInputTypeIdFragment on Feature {
		...FeatureKeyFieldFragment
		inputTypeId
	}
`;

export const FEATURE_SELECT_ID_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureSelectIdFragment on Feature {
		...FeatureKeyFieldFragment
		selectId
	}
`;

export const FEATURE_SWITCH_ID_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureSwitchIdFragment on Feature {
		...FeatureKeyFieldFragment
		switchId
	}
`;

export const FEATURE_NUMERICAL_ID_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureNumericalIdFragment on Feature {
		...FeatureKeyFieldFragment
		numericalId
	}
`;

export const FEATURE_UPDATED_AT_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureUpdatedAtFragment on Feature {
		...FeatureKeyFieldFragment
		updatedAt
	}
`;

export const FEATURE_PRICING_PLANS_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeaturePricingPlansFragment on Feature {
		...FeatureKeyFieldFragment
		pricingPlans {
			id
			name
		}
	}
`;

export const FEATURE_INPUT_TYPE_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureInputTypeFragment on Feature {
		...FeatureKeyFieldFragment
		inputType {
			id
			name
		}
	}
`;

//TODO apply discounts
export const FEATURE_NAME_DESCRIPTION_AND_LINKED_TO_TEMPLATE_FRAGMENT = gql`
	${FEATURE_NAME_FRAGMENT}
	${FEATURE_DESCRIPTION_FRAGMENT}
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureNameDescriptionAndLinkedToTemplateFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureNameFragment
		...FeatureDescriptionFragment
	}
`;

export const FEATURE_TYPE_IDS_AND_INPUT_TYPE_ID_FRAGMENT = gql`
	${FEATURE_INPUT_TYPE_ID_FRAGMENT}
	${FEATURE_SELECT_ID_FRAGMENT}
	${FEATURE_SWITCH_ID_FRAGMENT}
	${FEATURE_NUMERICAL_ID_FRAGMENT}
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureLevelIdsAndInputTypeIdFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureInputTypeIdFragment
		...FeatureSelectIdFragment
		...FeatureSwitchIdFragment
		...FeatureNumericalIdFragment
	}
`;

export const FEATURE_NUMERICAL_METRIC_FRAGMENT = gql`
	${NUMERICAL_UNIT_METRIC_FRAGMENT}
	${NUMERICAL_TIME_METRIC_FRAGMENT}
	${NUMERICAL_DISPLAYED_AS_FRAGMENT}
	${FEATURE_KEY_FIELD_FRAGMENT}
	${FEATURE_NAME_FRAGMENT}
	fragment FeatureNumericalMetricFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureNameFragment
		value {
			... on Numerical {
				...NumericalUnitMetricFragment
				...NumericalTimeMetricFragment
				...NumericalDisplayedAsFragment
			}
		}
	}
`;

export const FEATURE_VALUE_FRAGMENT = gql`
	${SELECT_FRAGMENT}
	${NUMERICAL_FRAGMENT}
	${SWITCH_FRAGMENT}
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment FeatureValueFragment on Feature {
		...FeatureKeyFieldFragment
		value {
			... on Select {
				...SelectFragment
			}
			... on Switch {
				...SwitchFragment
			}
			... on Numerical {
				...NumericalFragment
			}
		}
	}
`;

export const PUBLIC_FEATURE_VALUE_FRAGMENT = gql`
	${PUBLIC_SELECT_FRAGMENT}
	${PUBLIC_NUMERICAL_FRAGMENT}
	${PUBLIC_SWITCH_FRAGMENT}
	${FEATURE_KEY_FIELD_FRAGMENT}
	fragment PublicFeatureValueFragment on Feature {
		...FeatureKeyFieldFragment
		value {
			... on Select {
				...PublicSelectFragment
			}
			... on Switch {
				...PublicSwitchFragment
			}
			... on Numerical {
				...PublicNumericalFragment
			}
		}
	}
`;

export const FEATURE_FOR_COMPUTE_PRICE_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	${FEATURE_NUMERICAL_ID_FRAGMENT}
	${PUBLIC_FEATURE_VALUE_FRAGMENT}
	fragment FeatureForComputePriceFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureNumericalIdFragment
		...PublicFeatureValueFragment
	}
`;

export const FEATURE_TEMPLATE_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	${FEATURE_DESCRIPTION_FRAGMENT}
	${FEATURE_LINK_FRAGMENT}
	${FEATURE_NAME_FRAGMENT}
	${FEATURE_MEDIA_URL_FRAGMENT}
	${FEATURE_INPUT_TYPE_ID_FRAGMENT}
	${FEATURE_SELECT_ID_FRAGMENT}
	${FEATURE_SWITCH_ID_FRAGMENT}
	${FEATURE_NUMERICAL_ID_FRAGMENT}
	${FEATURE_INPUT_TYPE_FRAGMENT}
	${FEATURE_CREATED_AT_FRAGMENT}
	${FEATURE_UPDATED_AT_FRAGMENT}
	${FEATURE_SHOW_URL_PREVIEW}
	fragment FeatureTemplateFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureDescriptionFragment
		...FeatureLinkFragment
		...FeatureNameFragment
		...FeatureMediaURLFragment
		...FeatureInputTypeIdFragment
		...FeatureSelectIdFragment
		...FeatureSwitchIdFragment
		...FeatureNumericalIdFragment
		...FeatureInputTypeFragment
		createdById
		...FeatureCreatedAtFragment
		updatedById
		...FeatureUpdatedAtFragment
		...FeatureShowURLPreviewFragment
		nbOfLevels
		nbOfPriceModels
		createdBy {
			id
		}
		selectedCategories {
			id
			categoryId
			categorizationId
			category {
				id
				name
				index
				color
			}
		}
		product {
			id
			crmProduct {
				id
				name
			}
		}
	}
`;

export const FEATURE_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	${FEATURE_DESCRIPTION_FRAGMENT}
	${FEATURE_LINK_FRAGMENT}
	${FEATURE_NAME_FRAGMENT}
	${FEATURE_MEDIA_URL_FRAGMENT}
	${FEATURE_INPUT_TYPE_ID_FRAGMENT}
	${FEATURE_SELECT_ID_FRAGMENT}
	${FEATURE_SWITCH_ID_FRAGMENT}
	${FEATURE_NUMERICAL_ID_FRAGMENT}
	${FEATURE_INPUT_TYPE_FRAGMENT}
	${FEATURE_VALUE_FRAGMENT}
	fragment FeatureFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureDescriptionFragment
		...FeatureLinkFragment
		...FeatureNameFragment
		...FeatureMediaURLFragment
		...FeatureInputTypeIdFragment
		...FeatureSelectIdFragment
		...FeatureSwitchIdFragment
		...FeatureNumericalIdFragment
		...FeatureInputTypeFragment
		...FeatureValueFragment
		uuid
	}
`;

export const PUBLIC_FEATURE_FRAGMENT = gql`
	${FEATURE_KEY_FIELD_FRAGMENT}
	${FEATURE_DESCRIPTION_FRAGMENT}
	${FEATURE_LINK_FRAGMENT}
	${FEATURE_NAME_FRAGMENT}
	${FEATURE_MEDIA_URL_FRAGMENT}
	${FEATURE_INPUT_TYPE_ID_FRAGMENT}
	${FEATURE_SELECT_ID_FRAGMENT}
	${FEATURE_SWITCH_ID_FRAGMENT}
	${FEATURE_NUMERICAL_ID_FRAGMENT}
	${FEATURE_SECTIONS_FRAGMENT}
	${PUBLIC_FEATURE_VALUE_FRAGMENT}

	fragment PublicFeatureFragment on Feature {
		...FeatureKeyFieldFragment
		...FeatureDescriptionFragment
		...FeatureLinkFragment
		...FeatureNameFragment
		...FeatureMediaURLFragment
		...FeatureInputTypeIdFragment
		...FeatureSelectIdFragment
		...FeatureSwitchIdFragment
		...FeatureNumericalIdFragment
		...FeatureSectionsFragment
		...PublicFeatureValueFragment
	}
`;
